<template>
  <Transition name="fade">
    <div v-if="value" class="attend-modal" @click.self="handleClose">
      <div class="wrapper">
        <button class="btn-close" @click="handleClose">
          <TheCrossIcon />
        </button>
        <h4 class="mb-4">THANKS FOR VISITING!</h4>
        <p>
          We’re glad you’re enjoying The Cultivist app. To unlock this feature, you need to upgrade from an Enthusiast
          Membership to a Club Membership. Please click
          <a :href="`${marketingSiteBaseUrl}/the-club`" target="_blank" rel="noopener noreferrer">here</a> to read more
          about the benefits you can receive as a Club Member or
          <a href="mailto:contact@thecultivist.com?subject=Upgrade My Membership">contact us</a>
          to upgrade your membership.
        </p>
        <p>In the meantime, you are welcome to use your card at any of our partner museums.</p>
      </div>
    </div>
  </Transition>
</template>

<script>
import AppConfig from '@/config';
import TheCrossIcon from '@/components/icons/TheCrossIcon.vue';

export default {
  name: 'UpgradeFromEnthusiastMsgModal',
  components: { TheCrossIcon },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      marketingSiteBaseUrl: AppConfig.getAsString('marketingSiteBaseUrl'),
    };
  },
  methods: {
    handleClose() {
      this.$emit('input', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.attend-modal {
  position: fixed;
  width: 100%;
  top: 0;
  height: 100vh;
  left: 0;
  z-index: 999;
  background-color: rgba(#000, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;

  .wrapper {
    max-width: 800px;
    margin: 16px;
    padding: 15px 30px 30px 30px;
    position: relative;
    background-color: #fff;
  }

  .btn-close {
    display: block;
    margin-left: auto;
    margin-right: -15px;
    margin-bottom: 16px;

    @media (min-width: 400px) {
      margin-bottom: 0;
    }
  }
}
</style>
